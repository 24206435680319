<template>
<div>
    <AuthForm />
    <Join />
    <Agreement />
    <BecomePro />
    <DeleteComment />
    <DeleteGroup />
    <DeletePhoto />
    <DeletePost />
    <DeleteReply />
    <LeaveGroup />
    <PleaseLogIn />
    <PostForm />
    <SearchCardCollection />
    <Thanks />
</div>
</template>

<script>
    import AuthForm from './auth/AuthForm'
    import Join from './auth/Join'
    import Agreement from './auth/Agreement'
    import BecomePro from './BecomePro'
    import DeleteComment from './DeleteComment'
    import DeleteGroup from './DeleteGroup'
    import DeletePhoto from './DeletePhoto'
    import DeletePost from './DeletePost'
    import DeleteReply from './DeleteReply'
    import LeaveGroup from './LeaveGroup'
    import PleaseLogIn from './PleaseLogIn'
    import PostForm from './PostForm'
    import SearchCardCollection from './SearchCardCollection'
    import Thanks from './Thanks'

    export default {
        name: 'App',
        components: {
            AuthForm,
            Join,
            Agreement,
            BecomePro,
            DeleteComment,
            DeleteGroup,
            DeletePhoto,
            DeletePost,
            DeleteReply,
            LeaveGroup,
            PleaseLogIn,
            PostForm,
            SearchCardCollection,
            Thanks,
        },
    }
</script>